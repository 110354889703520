import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout location={location}>
{/*             <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />  */}
        <section className="hero  hero-centered">
          <div className="hero-body">
              <h1 className="post-title">
              {tagHeader}
              </h1>
          </div>
        </section>
        <div className="py-2">
          <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <li key={slug}>
                <Link className="font-bold my-1 block" to={slug}>{title}</Link>
              </li>
            )
          })}
          </ul>
          <h2 className="mt-8">Click on title to go to a post, <br/>or go back to <Link className="" to="/tags">all tags</Link></h2>
        </div>


      {/*
              This links to a page that does not yet exist.
              We'll come back to it!
            */}

    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`